<template>
  <v-container fluid v-if="show" id="download">
      
        <h1 class="mt-5 text-center primary--text">کەشفی پارەی کۆمپانیا</h1>
        <v-row>
            <v-col>
                <v-simple-table>
                <thead>
                    <tr>
                        <td class="primary--text text-center">#</td>
                        <td class="primary--text text-center">جۆری جووڵە</td>
                        <td class="primary--text text-center">بڕی</td>
                        <td class="primary--text text-center">بەروار</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(transition, i) in transactions" :key="i">
                        <td class=" text-center">{{i+1}}</td>
                        <td class=" text-center">{{transition.type == 'p'?'کڕین': transition=='rp'?'گەڕاندنەوەی کاڵا':'گەڕاندنەوەی قەرز'}}</td>
                        <td class=" text-center">{{` ${transition.type == 'p'? transition.total: -transition.total}`}}</td>
                        <td class=" text-center">{{new Date(transition.date).toISOString().split("T")[0]}}</td>
                    </tr>
                </tbody>
            </v-simple-table>
            </v-col>
        </v-row>
  </v-container>
</template>

<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
export default {
    data() {
        return {
            transactions: [],
            window: this.window
        }
    },
    async created() {
            const {data} = await this.axios.get('/report/supplier/'+ this.$route.params.supplier_id)
            this.transactions = await data 
    },
    async updated(){
        
            //      var canvas = document.getElementById('download') 
            //   html2canvas(canvas, {scale:4}).then(async (canvas)=>{
            //       var imgData = canvas.toDataURL('image/png')
            //       var doc = jsPDF(
            //             {
            //               orientation: 'p',
            //                 unit: 'mm',
            //                 format: 'a4',
            //                 putOnlyUsedFonts:true
            //             })
            //       await doc.addImage(imgData, 'PNG', 0, 0,210,100)
            //       await doc.save('ڕاپۆرتی کۆمپانیا')
            //       setTimeout(()=> window.close())
            //   })    
    },
    computed:{
        show(){
            return this.transactions.length> 0
        }
    }
   
}
</script>

<style scoped>
    tr td{
        border: 1px solid black;
        border-radius: 3%;
    }
    tr{
        
    }
   
</style>